import {
  Vue, Options
} from 'vue-class-component'
import swal from 'sweetalert'
import Header from '@/components/Header.vue'
import EditUserBill from '@/components/EditUserBill.vue'
import { userBillFactory } from '@/http/user-bill'
import { userFactory } from '@/http/user'
import { masterDataFactory } from '@/http/master-data'
import { billFactory } from '@/http/bill'
import Utils from '@/utils/utils'
import constants from '@/constants/constants'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'

@Options({
  components: {
    Header,
    VPagination,
    EditUserBill
  },
  data() {
    return {
      bills: [],
      billOption: [],
      students: [],
      orderStatuses: {
        "":"Semua Status",
        "110":"Aktif",
        "120":"Belum Lunas",
        "130":"Lunas",
        "140":"Kelebihan"
      },
      param: {
        status: "",
        keyword: "",
        school_year_id: "",
        month: "",
        order_by: "desc",
        order_at: "created_at",
        user_id: "",
        spp_bill_id: "",
        limit: 20,
        offset: 0,
        class_id: ""
      },
      data: {
        bill_name: '',
        bill_month: '',
        details: [],
        total_amount : 0,
        fine_rules: {
          value: 0,
          type: 'nominal',
          formula: 'flat'
        },
        description: null,
        due_date: null,
        dateline_date: '',
        is_published: '',
        state: '',
        user_updated: {
          name: ''
        },
        updated_at: '',
        log: []
      },
      schoolYears: [],
      months: [],
      classes: [],
      math_operations: [],
      data_master: {
        school_years: "1",
        months: "1",
        classes: "1",
        math_operations: "1"
      },
      totalPaid: 0,
      totalBill: 0,
      searchField: "",
      modal: false,
      currentPage: 1,
      totalPage: 1,
      constants: constants,
      save: null
      // billPublished: []
    }
  },
  watch: {
    '$route.query': {
      handler: 'onPageQueryChange',
      deep: true
    }
  },
  methods: {
    onPageChanged(page:any) {
      if (page) {
        this.currentPage = page
      }
      page = Math.round(this.currentPage)
      if (this.totalPage >= page) {
        this.$router.push({ query: { page } })
      }
    },
    onPageQueryChange() {
      this.param.offset = (this.currentPage - 1) * parseInt(this.param.limit)
      this.loadData()
    },
    /* checkAllPublished: function() {
      this.billPublished = []
      this.bills.forEach((bill:any) => {
        if (bill.is_published == 1) {
          this.billPublished.push(bill.id)
        }
      })
    }, */
    async getSuggestions(){
      if (this.searchField.length > 3) {
        var param = {
          "keyword": this.searchField
        }
        Promise.resolve(userFactory.students(param))
        .then(result => {
            this.students = result.data.users[0]
            this.students.forEach((value:any) => {
              if (value.child_name != null) {
                value.name = value.child_name
                value.user_id = value.child_user_id
              }
            })
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
          this.students = []
        })
      } else {
        this.students = []
      }
    },
    setSearch(search:any){
      this.searchField = search.name
      this.param.user_id = search.user_id
      this.modal = false
    },
    getMonth: function(date:any) {
      if (date != '' && date != undefined) {
        const d = date.split("-")
        const month  = d[2]
        return this.months[month].id
      } else {
        return ''
      }
    },
    beautifyDatetime: function(date:any) {
      return Utils.beautifyDatetime(date)
    },
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    formatDate: function(date:any) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
      if (month.length < 2)
        month = '0' + month
      if (day.length < 2)
        day = '0' + day
      return [year, month, day].join('-')
    },
    publish: function(isPublished:any) {
      var totalBill = this.totalBill
      swal({
        title: "Apakah anda yakin?",
        text:  (isPublished) ? "Sebanyak " + totalBill + " tagihan hasil pencarian akan ditampilkan ke user." : "Sebanyak " + totalBill + " tagihan hasil pencarian batal ditampilkan ke user.",
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true
        },
        dangerMode: true,
      })
      .then((willGenerate) => {
        if (willGenerate) {
          if (this.searchField.length == 0) {
            this.param.user_id = ''
          }
          if (this.param.user_id != '') {
            this.param.class_id = ''
          }
          var param = {
            is_published: isPublished,
            status: this.param.status,
            month: this.param.month,
            keyword: this.param.keyword,
            school_year_id: this.param.school_year_id,
            user_id: this.param.user_id,
            class_id: this.param.class_id,
            spp_bill_id: this.param.spp_bill_id
          }
          Promise.resolve(userBillFactory.userBillPublish(param))
          .then(results => {
            if (results.error == false) {
              var num_bill = results.data.total_affected
              if (isPublished) {
                swal("Sebanyak "+ num_bill +" tagihan ditampilkan ke user", "", "success")
              } else {
                swal("Sebanyak "+ num_bill +" tagihan batal ditampilkan ke user", "", "success")
              }
              this.loadData()
            } else if (results.response.data.error == true) {
              var errorMessage = results.response.data.messages
              var msg = ''
              for (let i in errorMessage) {
                msg += errorMessage[i] + "<br>"
              }
              var length = 100
              msg = msg.substring(0, length)
              swal({
                text: (isPublished) ? "Gagal menampilkan tagihan ke user" : "Gagal membatalkan tagihan ke user",
                icon: "error",
                content: {
                  element: "p",
                  attributes: {
                    innerHTML: msg
                  }
                }
              })
            }
          }).catch((e) => {
            swal("Gagal melakukan request", "", "error")
            console.log(e)
          })
        }
      })
      return
    },
    publishByID: function(bill:any, e:any) {
      swal({
        title: "Apakah anda yakin?",
        text:  (!bill.is_published) ? "Tagihan " + bill.bill.name + " akan ditampilkan ke " + bill.user_name + "." : "Tagihan " + bill.bill.name + " batal ditampilkan ke " + bill.user_name + ".",
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true
        },
        dangerMode: true,
      })
      .then((willGenerate) => {
        if (willGenerate) {
          if (this.searchField.length == 0) {
            this.param.user_id = ''
          }
          if (this.param.user_id != '') {
            this.param.class_id = ''
          }
          var param = {
            is_published: !bill.is_published,
            school_year_id: this.param.school_year_id,
            spp_bill_user_id: bill.id
          }
          Promise.resolve(userBillFactory.userBillPublish(param))
          .then(results => {
            if (results.error == false) {
              if (!bill.is_published) {
                swal("Tagihan " + bill.bill.name + " ditampilkan ke pengguna.", "", "success")
              } else {
                swal("Tagihan " + bill.bill.name + " batal ditampilkan ke pengguna.", "", "success")
              }
              this.loadData()
            } else if (results.response.data.error == true) {
              var errorMessage = results.response.data.messages
              var msg = ''
              for (let i in errorMessage) {
                msg += errorMessage[i] + "<br>"
              }
              var length = 100
              msg = msg.substring(0, length)
              swal({
                text: (!bill.is_published) ? "Gagal menampilkan tagihan ke user" : "Gagal membatalkan tagihan ke user",
                icon: "error",
                content: {
                  element: "p",
                  attributes: {
                    innerHTML: msg
                  }
                }
              })
            }
          }).catch((e) => {
            swal("Gagal melakukan request", "", "error")
            console.log(e)
          })
        } else {
          // this.checkAllPublished()
          var checkbox = e.target
          checkbox.checked = !checkbox.checked
        }
      })
      return
    },
    getBill: function() {
      var param = {
        school_year_id: this.param.school_year_id
      }
      Promise.resolve(billFactory.bill(param))
      .then(result => {
        this.billOption = result.data.bills
      })
      .catch((e) => {
        console.log(e)
      })
    },
    edit: function(type: string, bill_id: any, bill_user_id: any) {
      Promise.resolve(userBillFactory.billUserDetail(bill_id, bill_user_id))
      .then(result => {
          var bill_user = result.data.bill_user
          var data = {
            id: bill_user.id,
            spp_bill_id: bill_user.spp_bill_id,
            school_year_id: bill_user.school_year_id,
            bill_name: bill_user.bill.name,
            bill_month: this.getMonth(bill_user.id),
            active_date: bill_user.active_date.substring(0, 10),
            deadline_date: new Date(bill_user.deadline_date.substring(0, 10)),
            due_date: new Date(bill_user.due_date.substring(0, 10)),
            total_amount: bill_user.total_amount,
            late_charge: bill_user.late_charge,
            details: bill_user.details,
            description: bill_user.bill.description,
            is_published: (bill_user.is_published == 1) ? true : false,
            fine_rules: bill_user.bill.fine_rules,
            user_updated: bill_user.user_updated,
            log: bill_user.log,
            updated_at: Utils.beautifyDatetime(bill_user.updated_at)
          }
          if (bill_user.bill.fine_rules.length == 0) {
            var fine_rules = {
              value: 0,
              type: 'nominal',
              formula: 'flat'
            }
            data.fine_rules = fine_rules
          }
          this.data = data
          this.data.state = type
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    exportBill: function() {
      if (this.searchField.length == 0) {
        this.param.user_id = ''
      }
      if (this.param.user_id != '') {
        this.param.class_id = ''
      }
      var param = {
        status: this.param.status,
        keyword: this.param.keyword,
        school_year_id: this.param.school_year_id,
        month: this.param.month,
        order_by: "desc",
        order_at: "created_at",
        user_id: this.param.user_id,
        spp_bill_id: this.param.spp_bill_id,
        class_id: this.param.class_id
      }
      Promise.resolve(userBillFactory.exportBill(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadData: function(type:any) {
      if (type === 'refresh') {
        this.onPageChanged(1)
      }
      if (this.searchField.length == 0) {
        this.param.user_id = ''
      }
      if (this.param.user_id != '') {
        this.param.class_id = ''
      }
      var param = {
        status: this.param.status,
        keyword: this.param.keyword,
        school_year_id: this.param.school_year_id,
        month: this.param.month,
        order_by: "desc",
        order_at: "created_at",
        user_id: this.param.user_id,
        spp_bill_id: this.param.spp_bill_id,
        limit: this.param.limit,
        offset: this.param.offset,
        class_id: this.param.class_id
      }
      Promise.resolve(userBillFactory.userBillParam(param))
      .then(result => {
        this.totalPaid = 0
        this.bills = result.data.bill_users
        this.bills.forEach((value:any) => {
          this.totalPaid += value.total_paid
        })
        this.totalBill = result.data.total_rows
        this.modal = false
        var limit = parseInt(this.param.limit)
        var totalPage = Math.round((this.totalBill + limit - 1) / limit)
        if ((totalPage * limit - this.totalBill) >= limit) {
          totalPage = Math.round(this.totalBill / limit)
        }
        this.totalPage = totalPage
        // this.checkAllPublished()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadDataMaster: function() {
      Promise.resolve(masterDataFactory.master_datas(this.data_master))
      .then(result => {
        this.schoolYears = result.data.school_years
        this.months = result.data.months
        this.classes = result.data.classes
        this.math_operations = result.data.math_operations
        this.schoolYears.forEach((school_year:any) => {
          if (school_year.status == "Active") {
            this.param.school_year_id = school_year.school_year_id
            this.school_year_name = school_year.name
          }
        })
        this.getBill()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    }
  },
  async mounted () {
    await this.loadDataMaster()
  }
})

export default class BillUser extends Vue {}
