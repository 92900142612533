import axios from 'axios'

class BillFactory {

  async bill (data: any) {
    try {
      let url = '/bills'
      var response = await axios.get(url, {
        params: {
          school_year_id: data.school_year_id
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async billDetail (id: any) {
    try {
      let url = `/bills/${id}`
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async createBill (data: any) {
    try {
      let url = '/bills'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async updateBill (id: any, data: any) {
    try {
      let url = `/bills/${id}`
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async generateBill (id: any) {
    try {
      let url = `/bills/${id}/users`
      var response = await axios.post(url)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async generateBillByUserIds (id: any, student_ids: any) {
    try {
      let url = `/bills/${id}/users/user_ids`
      var data = {
        student_ids: student_ids
      }
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

}


export let billFactory = new BillFactory()
