import {
  Vue, Options
} from 'vue-class-component'
import swal from 'sweetalert'
import Header from '@/components/Header.vue'
import { userBillFactory } from '@/http/user-bill'
import { masterDataFactory } from '@/http/master-data'
import Utils from '@/utils/utils'
import constants from '@/constants/constants'

@Options({
  components: {
    Header,
  },
  data() {
    return {
      months: [],
      bill_details: {},
      update_details: [],
      error_messages: [],
      file: '',
      data_master: {
        months: "1",
      },
      imported: false,
      processing: false
    }
  },
  methods: {
    loadDataMaster: function() {
      Promise.resolve(masterDataFactory.master_datas(this.data_master))
      .then(result => {
        this.months = result.data.months
      })
    },
    getMonthByID: function(id:any) {
      if (id == '') return ''

      return this.months[id].id
    },
    templateImport: function() {
      Promise.resolve(userBillFactory.templateDetailImport())
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    importDetail: function () {
      this.imported = false

      if (this.file == '' && this.$refs.file.value != null) {
        swal("Harap pilih berkas yang akan diimpor", "", "error")
        return
      }

      let formData = new FormData();
      formData.append('excel', this.file);
      Promise.resolve(userBillFactory.importDetail(formData))
        .then((response) => {
          if (!response.data) {
            swal("Gagal melakukan request", "", "error")
            return
          }

          this.imported = true
          this.$refs.file = null
          if (this.$refs.file) {
            this.$refs.file.value = null
          }

          if (response.data.bill_details) {
            this.bill_details = response.data.bill_details
          }

          if (response.data.update_details) {
            this.update_details = response.data.update_details
          }

          if (response.data.error_messages) {
            this.error_messages = response.data.error_messages
          }

        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        })
    },
    applyImportDetail: function() {
      this.processing = true

      Promise.resolve(userBillFactory.applyImportDetail(this.update_details))
        .then((response) => {
          if (response.data.affected_rows) {
            swal("Jumlah Rincian Tagihan Terupdate: "+ response.data.affected_rows, "", "info")
          }
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        }).finally(() => {
          this.processing = false
        })
    }
  },
  async mounted () {
    await this.loadDataMaster()
  }
})

export default class BillUserImport extends Vue {}
